import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/PageWrapper';

import MainButton from '../components/Button';
import FooterCta from '../components/FooterCta';


const LandingTemplate = ({
    data: {
        datoCmsLandingpage: {
            title,
            seo,
            heroTitle1,
            heroTitle2,
            heroText,
            key1,
            key1Text,
            key2,
            key2Text,
            key3,
            key3Text,
            action1,
            action2,
        },
        datoCmsContactpage: { 'id': contactPageId},
        datoCmsHomepage: { 'id': homePageId},
    },
    pageContext,
}) => (
    <PageWrapper
        pageData={pageContext}
        seoTitle={seo?.seoTitle}
        seoDescription={seo?.seoDescription}
        seoImage={seo?.seoImage?.seoImageUrl}
    >
        <div className='AboutPageNavBackground'></div>
        <div className='LandingWrapper'>
            <div className='LandingHero'>
                <div className='LandingHeroShapeRT'></div>
                <div className='LandingHeroShapeRT LandingHeroShapeRT__Light'></div>
                <div className='LandingHeroShapeRT LandingHeroShapeRT__Light2'></div>
                <div className='LandingHeroShapeLB'></div>
                <div className='LandingHeroShapeLB LandingHeroShapeLB__Light'></div>
                <div className='LandingHeroTextWrapper'>
                    <h1 className='LandingHeroH1'>{heroTitle1}</h1>
                    <h1 className='LandingHeroH1'>{heroTitle2}</h1>
                    <div className='LandingHeroTextContainer'>
                        <p className='LandingHeroText'>{heroText}</p>
                    </div>
                    <MainButton recordId={contactPageId} className={'btn_wht'}>
                        Get in Touch
                    </MainButton>
                </div>
            </div>
        </div>
        <div className='LandingKeyContainer'>
            <div className='LandingKeyChild'>
                <div className='LandingKeyInner'>
                    <h1 className='LandingKey'>{key1}%</h1>
                    <a className='LandingKeyText'>{key1Text}</a>
                </div>
            </div>
            <div className='LandingKeyChild'>
                <div className='LandingKeyInner'>
                    <h1 className='LandingKey'>{key2}%</h1>
                    <a className='LandingKeyText'>{key2Text}</a>
                </div>
            </div>
            <div className='LandingKeyChild'>
                <div className='LandingKeyInner'>
                    <h1 className='LandingKey'>{key3}%</h1>
                    <a className='LandingKeyText'>{key3Text}</a>  
                </div> 
            </div>
            
        </div>
        <div className='LandingActionContainer'>
            <h2 className='LandingActionH2'>{action1}</h2>
            <h2 className='LandingActionH2'>{action2}</h2>
            <div className='LandingActionBtn'>
                <MainButton recordId={homePageId} className={'btn_blk'}>
                    Explore
                </MainButton>
            </div>
        </div>
    </PageWrapper>
);

export default LandingTemplate;

export const query = graphql`
    query LandingpageQuery($locale: String!) {
        datoCmsLandingpage(locale: { eq: $locale }) {
            locale
            title
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
            heroTitle1
            heroTitle2
            heroText
            key1
            key1Text
            key2
            key2Text
            key3
            key3Text
            action1
            action2
        }
        datoCmsContactpage(locale: { eq: $locale }) {
            id: originalId
        }
        datoCmsHomepage(locale: { eq: $locale }) {
            id: originalId
        }
    }
`;